


































































import { mapMutations, mapState } from 'vuex';
import Vue from 'vue';
import RepositoryFactory from '@/services/RepositoryFactory';
import { IItem } from '../../components/ui/types';
import IBoxInventoryTemplateRepository, {
  INewBoxInventoryTemplate,
  IBoxInventoryTemplateItem,
} from '@/services/Repositories/boxInventoryTemplate/types';
import IInventoryItemsRepository from '@/services/Repositories/inventoryItemRepository/types';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'NewInventoryTemplate',
  components: {
    ItemCounter: () => import('@/components/ui/ItemCounter.vue'),
    TheFabButton: () => import('@/components/ui/TheFabButton.vue'),
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
  },
  data() {
    return {
      shortDescription: '',
      startDescription: '',
      atLeastOneItemSet: false,
      savePopupIsOpen: false,
      deletePopupIsOpen: false,
      confirmed: false,
      confirmedText: '',
      templateItems: [] as Array<IBoxInventoryTemplateItem>,
    };
  },
  methods: {
    ...mapMutations('globals', [
      'SET_ALERT_DIALOG',
      'SET_ALERT_TEXT',
      'SET_ALERT_TITLE',
      'SET_PAGE',
    ]),
    reRoute() {
      this.$router.replace({ name: 'InventoryTemplates' });
    },
    updateItem(item: IItem): void {
      const indexOfItem = this.templateItems.findIndex(({ id }: any) => id === item.id);
      if (item.amount >= 0 && !Number.isNaN(item.amount)) {
        Vue.set(this.templateItems[indexOfItem], 'amount', item.amount);
      } else {
        Vue.set(this.templateItems, indexOfItem, { amount: item.amount });
      }
      this.atLeastOneItemSet = true;
    },
    async saveInventoryTemplate(payload: INewBoxInventoryTemplate) {
      const data = await this.boxInventoryTemplateRepository.create(payload);
    },
    async updateInventoryTemplate(payload: INewBoxInventoryTemplate) {
      const data = await this.boxInventoryTemplateRepository.update(this.$route.params.id, payload);
    },
    async deleteInventoryTemplate() {
      const data = await this.boxInventoryTemplateRepository.delete(this.$route.params.id);
    },
    saveInventoryTemplateHandler() {
      this.savePopupIsOpen = false;
      this.confirmedText = String(this.$t('inventory-templates.saved-new-inventory-template'));
      const newTemplateItems = this.templateItems.map((item: any) => {
        const { amount } = item;
        const inventoryItem = `/api/inventory_items/${item.id}`;
        return { inventoryItem, amount };
      });
      if (!this.newInventoryTemplate) {
        this.updateInventoryTemplate({
          name: this.shortDescription,
          boxInventoryTemplateItems: newTemplateItems,
        });
      } else {
        this.saveInventoryTemplate({
          name: this.shortDescription,
          boxInventoryTemplateItems: newTemplateItems,
        });
      }

      this.confirmed = true;
    },
    deleteInventoryTemplateHandler() {
      this.deletePopupIsOpen = false;
      this.confirmedText = String(this.$t('inventory-templates.deleted-new-inventory-template'));
      this.deleteInventoryTemplate();
      this.confirmed = true;
    },
    async getInventoryTemplate() {
      const { data } = await this.boxInventoryTemplateRepository.getOne(this.$route.params.id);
      const newDesciption = data.name;
      this.shortDescription = newDesciption;
      this.startDescription = newDesciption;
      const itemsInBox = data.boxInventoryTemplateItems;
      itemsInBox.forEach(async (item) => {
        const { inventoryItem } = item;
        const id = typeof inventoryItem === 'string' ? inventoryItem : '';
        const itemId = id.split('/').pop() || '';
        /* eslint-disable no-param-reassign */
        this.templateItems.forEach((globalItem: any) => {
          if (globalItem.id === itemId) {
            globalItem.amount = +item.amount;
          }
        });
        /* eslint-disable no-param-reassign */
      });
    },
    async getInventoryItems() {
      const data = await this.inventoryItemsRepository.get();
      const hydraMember = data.data['hydra:member'];
      (this as any).templateItems = hydraMember;
      // eslint-disable-next-line max-len
      this.templateItems.forEach(
        (item: any, index: number) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          this.$set(this.templateItems, index, { ...item, amount: 0 }),
        // eslint-disable-next-line function-paren-newline
      );
    },
  },
  async created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    await this.getInventoryItems();
    if (!this.newInventoryTemplate) {
      await this.getInventoryTemplate();
      this.$headerService.config({
        title: this.shortDescription,
        icons: { icon: icons.back, action: actions.goBack },
        page: { name: 'InventoryTemplates' },
      });
    } else {
      this.$headerService.config({
        title: 'inventory-templates.add-new',
        icons: { icon: icons.back, action: actions.goBack },
        page: { name: 'InventoryTemplates' },
      });
    }
    this.SET_PAGE('InventoryTemplates');
  },
  watch: {
    componentUpdated: {
      handler(newVal: boolean) {
        const { icons, actions } = this.$headerService.getIconsAndActions();
        if (newVal) {
          this.$headerService.setHeaderIcons({ icon: icons.cancel, action: actions.close });
        } else {
          this.$headerService.setHeaderIcons({ icon: icons.back, action: actions.goBack });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('globals', ['alertText', 'alertTitle', 'alertDialogIsOpen']),
    ...mapState('configuration', ['newInventoryTemplate', 'description', 'items']),
    componentUpdated(): boolean {
      return !!this.shortDescription || !!this.atLeastOneItemSet;
    },
    boxInventoryTemplateRepository(): IBoxInventoryTemplateRepository {
      return factory.get('boxInventoryTemplates') as IBoxInventoryTemplateRepository;
    },
    inventoryItemsRepository(): IInventoryItemsRepository {
      return factory.get('inventoryItems') as IInventoryItemsRepository;
    },
    buttonIsDisabled(): boolean {
      if (!this.newInventoryTemplate) {
        return true
          ? this.shortDescription !== this.startDescription || this.atLeastOneItemSet
          : false;
      }
      return true ? this.shortDescription !== this.startDescription : false;
    },
  },
});
